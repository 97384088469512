.dialog {
	position: fixed;
	top:0;
	left: 0;
	bottom:0;
	right: 0;
	background: rgba(#000, .3);
	opacity: 0;
	z-index: -1;
	transition:all 300ms ease-in-out;
	overflow: auto;
	padding:10px;
	
	&.is-active {
		opacity: 1;
		z-index: 999;
	}
	.inner-dialog {
		background: #fff;
		max-width: 480px;
		margin: 0 auto;
		@include vertical-align(50%);
		padding: 30px 30px 10px;
		border-radius: 2px;
		box-shadow: 0px 1px 3px rgba(#000, .2);
		
		h2.dialog-title {
			margin-bottom: 40px;
		}
	}
}
.modal {
	position: fixed;
	top:0;
	left: 0;
	bottom:0;
	right: 0;
	background: rgba(#000, .3);
	opacity: 0;
	z-index: -1;
	transition:all 300ms ease-in-out;
	overflow: auto;
	padding:0px;
	
	
	&.white {
		background: #fff;
		/*
		top:10px;
		left: 10px;
		bottom:10px;
		right: 10px;
		*/
		.inner-modal {
			box-shadow: none;
		}
		
		.close-modal.cross {
		
			background: url('../img/icon-close-dark.svg') no-repeat center center;
			
		}
	}
	
	&.is-active {
		opacity: 1;
		z-index: 999;
	}
	
	
	
	.close-modal.cross {
		width: 32px;
		height: 32px;
		position: fixed;
		display: block;
		top:30px;
		right: 30px;
		background: url('../img/icon-close-white.svg') no-repeat center center;
		z-index: 9999;
		cursor: pointer;
	}
	
	&.vertical-center {
		//.close-modal.cross { display: none; }	
		.inner-modal {
			@include vertical-align(50%);
		}
	}
	
	.inner-modal {
		background: #fff;
		max-width: 900px;
		overflow: visible;
		margin: 0 auto;
		//
		padding: 30px;
		border-radius: 2px;
		box-shadow: 0px 1px 3px rgba(#000, .2);
		
		h2.modal-title {
			margin-bottom: 20px;
		}
	}
	
	@include breakpoint(large up){
		
		padding: 50px;
		.inner-modal {
			padding: 50px 50px 20px;
		}
	}
}