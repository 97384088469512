body.login {
	
	main {
		padding: 0;
		height: 100vh;
		
	}
	
	main >.row {
		
		height: 100%;
		background:url('../img/login-image.jpg') no-repeat center center;
		background-size:cover;
	}
	
	.login-wrapper {
		background: #fff;
		height: 100vh;
		
		.inner-content {
			padding: 0px;	
			@include vertical-align(50%);		
		}
		
		
		
			//color: #fff;
			
			//p { color: #fff; }
			//padding: 40px 30px;
			
			img {
				margin-bottom: 40px;
				width: 100%;
				max-width: 160px;
			}
			
	
		
		
		.form-wrapper {
			height: 60vh;
		}
		
		
		
		@include breakpoint(large up){
			.inner-content {
				//padding: 40px 30px;
			}
		}
	}
	
	form {
		text-align: left;
		max-width: 460px;
		margin: 0 auto 0px;
		padding: 20px;
		
		
		@include breakpoint(large up){
			padding:40px;
		}
		
		button {
			margin-right: 0;
		}
		
		.login-field-container {
			display: block;
			position: relative;
			
			&:before {
				content:'';
				display: block;
				position: absolute;
				left: 0px;
				top:0%;
				width:50px;
				height: 100%;
				border-right: 1px solid $medium-gray;
				background:url('../img/icon-user-small.svg') no-repeat center center;
			}
			
			input { padding-left: 60px;}
		}
		
		.password-field-container {
			display: block;
			position: relative;
			
			&:before {
				content:'';
				display: block;
				position: absolute;
				left: 0px;
				top:0%;
				width:50px;
				height: 100%;
				border-right: 1px solid $medium-gray;
				background:url('../img/icon-lock.svg') no-repeat center center;
			}
			
			input { padding-left: 60px;}
		}
		
		.login-remeber-container { position: relative; top:5px; left:14px;}
	}
	
	a.forgotten-password {
		color: $header-color;
		font-style: italic;
		font-weight: 600;
		font-size: 16px;
		text-decoration: underline;
		display: block;
		margin-top: 80px;
	}
}