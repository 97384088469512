header[role=banner]{
	position: fixed;
	top:0;
	left: 0;
	width: 100%;
	height: 56px;
	text-align: center;
	z-index: 60;
	background: $body-background;
	
	span.logo {
		
		background: url('../img/logo.svg') no-repeat center center;
		background-size:contain; 
		width: 50px;
		height: 30px;
		display: none;
		margin-top: 13px;
		opacity: .8;
		position: relative;
		left:33px;
		
		@include breakpoint(medium up){
			display: inline-block;
		}
	}
	
	a:first-child {
		float:left;
		padding:18px 18px;
		line-height: 21px;
		color: #9B9B9B;
		text-transform: uppercase;
		font-size: 13px;
		font-weight: 600;
		letter-spacing: .02em;
		
		&:before {
			content:'';
			width: 21px;
			height: 21px;
			display: inline-block;
			background: url('../img/icon-grid-white.svg') no-repeat center center;
			background-size:contain;
			margin-right: 15px;
			vertical-align: middle;
			position: relative;
			top:-1px;
			opacity: .9;
		}
	}
	
	span {
		display: inline-block;
	}
	
	a.user-nav{
		float: right;
		padding:17px 18px;
		color: #9B9B9B;
		&:before {
			content:'';
			width: 22px;
			height: 22px;
			display: inline-block;
			background: url('../img/icon-user-white.svg') no-repeat center center;
			background-size:contain;
			vertical-align: middle;
			margin-right: 15px;
			position: relative;
			top:-1px;
			opacity: .5;
		}
		
		&:after {
			content:'';
			width: 21px;
			height: 17px;
			display: inline-block;
			background: url('../img/icon-toggle-menu-in.svg') no-repeat center center;
			background-size:contain;
			vertical-align: middle;
			margin-left: 15px;
			position: relative;
			top:-1px;
			opacity: .8;
		}
		
		&:hover {
			&:before {
				opacity: 1;
			}
			&:after {
				opacity: 1;
			}
		}
	}
}


nav[role=complementary]{
	position: fixed;
	width: 320px;
	top:0;
	right: -320px;
	
	height: 100vh;
	background: #454545;
	z-index: 101;
	transition:right 300ms ease-in-out;
	
	
	> header {
		height: 56px;
		border-bottom: 1px solid rgba(#fff, .1);
		
		span { 
			float:right;
			padding: 20px 18px;
			display: inline-block;
			text-transform: uppercase;
			font-size: 13px;
			color: #9B9B9B;
			line-height: 15px;
			cursor: pointer;
			
			&:after {
				content:'';
				margin-left: 15px;
				width: 14px;
				height: 14px;
				display: inline-block;
				background: url('../img/icon-close-white.svg') no-repeat center center;
				opacity: .8;
				vertical-align: middle;
				position: relative;
				top:-2px;
			}
		}
	}
	
	> .content {
		text-align: center;
		display: block;
		margin: 0;
		width: 100%;
		height: calc(100vh - 56px);
		overflow: auto;
		color: #9B9B9B;
		line-height: 1.2em;
		
		h2, h3, h4, h5 {
			color: #fff;
			font-weight: 600;
		}
		
		h3,h4 {
			
			margin: 0 0 4px;
		}
		
		
		.user-info {
			padding:60px 30px 60px 30px;
			
			.user-name {
				&:before {
					content:'';
					width: 28px;
					height: 30px;
					display: block;
					background: url('../img/icon-user-avatar.svg') no-repeat center center;
					background-size: contain;
					margin:0 auto 20px;
					clear: both;
					opacity: .8;
				}
				h3 {}
				h4 {
					clear: both;
				}
			}
			
			.company-address {
				
				margin-bottom: 40px;
				
				span {
				
					display: block;
				}
			}
			
			.btn {
				clear: both;
				margin-bottom: 20px;
			}
			
		}
		
		a.user-email {
			display: inline-block;
			margin-bottom: 40px;
		}
		
		.support-info {
			position: absolute;
			bottom:0;
			left: 0;
			width: 100%;
			padding: 60px 30px 30px;
			border-top:1px solid rgba(#fff, .1);
			
			&:before {
				content:'';
				width: 32px;
				height: 32px;
				display: block;
				background: url('../img/icon-support-white.svg') no-repeat center center;
				background-size: contain;
				margin:0 auto 20px;
				clear: both;
				opacity: .8;
			}
			
		}
	}
}

&.user-menu-active {
	nav[role=complementary]{
		right: 0;
	}		
}