@mixin vertical-align($val) {
  position: relative;
  top: $val;
  -webkit-transform: translateY(-$val);
  -ms-transform: translateY(-$val);
  transform: translateY(-$val);
}

@mixin reset-vertical-align {
  position: relative;
  top: 48%;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

@mixin linearGradient($top, $bottom){
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
}

@mixin horizontalGradient($from, $to, $stop: 100%) {
  /* fallback/image non-cover color */
  background-color: $from;

  /* Firefox 3.6+ */
  background-image: -moz-linear-gradient(left, $from 0%, $to $stop);

  /* Safari 4+, Chrome 1+ */
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, $from), color-stop($stop, $to));

  /* Safari 5.1+, Chrome 10+ */
  background-image: -webkit-linear-gradient(left, $from 0%, $to $stop);

  /* Opera 11.10+ */
  background-image: -o-linear-gradient(left, $from 0%, $to $stop);

  /* IE10+ */
  background: -ms-linear-gradient(left, $from 0%, $to $stop);

  /* Standard */
  background: linear-gradient(to right, $from 0%, $to $stop);

  /* IE6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}


table.pickup-archive {
	
	margin-bottom: 50px;
	
	@include breakpoint (large up){
		margin-bottom: 80px;
	}
	
	tbody {
		tr {
			td:last-child { text-align: right;}
		}
	}
}

.button.x-large {
	margin: 20px 0px 50px !important;
	display: inline-block;
	@include breakpoint (medium up){
		padding: 24px 30px 24px 80px !important;
		margin-bottom: 80px !important
	}
}

.delete-entry,
.edit-entry,
.copy-entry {
	
	display: inline-block;
	text-indent: 9999px;
	width: 24px;
	height: 24px;
	position: relative;
	cursor: pointer !important;
	overflow: hidden;
	
	&:after {
		content:'';
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0px;
		top:0;
		
	}

}

.edit-entry {

	
	&:after {
	
		background: url('/assets/img/icon-pencil.svg') no-repeat center center;	
	}
}

.copy-entry {

	
	&:after {
	
		background: url('/assets/img/icon-copy.svg') no-repeat center center;	
	}
}

.delete-entry {

	
	&:after {

		background: url('/assets/img/icon-bin.svg') no-repeat center center;	
	}
}

body.modal-is-active {
	overflow: hidden;
}

main[role=main]{
	background: #fff;
	padding-top: 56px;
	min-height: calc(100vh - 56px);
	
	&.dark-theme {
		background: #333333;
		color: $medium-gray;
		h1, h2, h3, h4, h5 {
			color: #fff;
		}
		
		p {
			color: $medium-gray;
		}
		
		table, tr, th {
			background: transparent;
			
			h4 {
				margin: 0;
			}
		}
		
		.button.hollow {
			border-color: #fff;
		}
	}
	//padding:18px;
	
	@include clearfix;
	
	.sidebar {
		
		background: linear-gradient(to bottom, #fafafa 0%,#fafafa 95%,#f2f2f2 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#f2f2f2',GradientType=1 );
		padding:0;
		margin:0 0 50px 0;
		border-bottom: 1px solid #e4e4e4;
		@include clearfix;
		
		img { 
			float:left;
			max-height: 90px;
			margin-right: 0px;
			margin-bottom: 0px;
			padding:30px;
			border-right: 1px solid #e4e4e4;
		}
		
		h3 {
			line-height: 90px;
			margin: 0;
			font-size: 21px;
			text-align: center;
		}
		
		@include breakpoint(small only){
			h3 {
				font-size: 17px;
			}
		}
		
		@include breakpoint(small only){
			
			.sub-menu {
				display: none;
				list-style: none;
				margin: 0px 0;
				padding: 10px 0;
				border-top:1px solid #e4e4e4;
				
				li { 
					
					display: block; 
					margin-right: 20px;
					
					a {
						display: block;
						color: $header-color;
						line-height: 40px;
						padding:0 20px;
					}
					
					&.active a {
						font-weight: 600;
					}
				}
			}
			
		}
		
		@include breakpoint(medium only){
			.sub-menu {
				list-style: none;
				margin: 0px 0 -1px;
				border-top:1px solid #e4e4e4;
				li {
					display: inline-block;
				
					a {
						display: block;
						padding: 0 20px;
						line-height: 50px;
						color: $header-color;
						border-right: 1px solid #e4e4e4;
					}
					
					&.active a{
						background: #fff;
						//border-left: 1px solid #e4e4e4;
						
						font-weight: 600;
					}
				}
				
			}
		}
	}
	
	@include breakpoint(large up){
		.sidebar {
			position: fixed;
			text-align: center;
			top:56px;
			height: 100vh;
			left: 0;
			width:300px;
			padding: 70px 0 0 0;
			border-bottom:none;
			border-right: 1px solid #e4e4e4;
			background: #ffffff;
			background: linear-gradient(to right, #ffffff 0%,#ffffff 95%,#f7f7f7 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f7f7f7',GradientType=1 );
			
			
			h3 {
				line-height: normal;
			}
			img { 
				float:none;
				max-height: none;
				margin:0 0 30px;
				border:none;
				padding: 0;	
			}
			
			.sub-menu {
				text-align: left;
				
				margin: 50px 0;
				padding: 0;
				border-top:1px solid #d4d4d4;
				
				li {
					margin: 0;
					border-bottom:1px solid #d4d4d4;
					
					&.active {
						border-left:5px solid $primary-color;
						margin-right: -1px;
						width:calc(100% + 1px);
						background: #fff;
						font-weight: 600;
					}
					
					a {
						display: block;
						padding: 15px 30px;
						color: $body-font-color;
					}
				}
			}
		}
	}
	.content {
				
		padding: 0px 5px;
		
		@include breakpoint(large up){
			padding: 60px;
			
			&.has-sidebar{
				
				margin-left: 300px;
				width: calc(100% - 300px);
			}
			
			
		}
		
		@include breakpoint(xlarge up){
			padding: 100px;
		}
	}
}

.account-page main .content {
		
	@include breakpoint(large up){
		margin-right: 320px;
	}
}

ul.notices {
	margin: 0 0 30px 0;
	list-style: none;
	
	li {
		border:1px solid ;
		padding:25px 30px 10px 110px;
		margin-bottom: 10px;
		position: relative;
		
		&:before {
			content:'';
			width: 80px;
			height: 100%;
			display: block;
			position: absolute;
			top:0;
			left: 0;
			margin:0;
			background-position: center center;
		}
		
		p {
			margin-bottom: 10px;
			font-weight: 600; 
			
			&.meta {
				padding-top: 15px;
				border-top:1px solid transparent;
				font-weight: 400;
				span {
					margin-right: 10px;
				}
				
				a {
					text-decoration: none;
					font-weight: 600;
					float:right;
				}
			}
		}
		
		a {
			text-decoration: underline;
		}
		
		&.notice {
			border-color:$success-color;
			background: lighten($success-color, 30%);
			
			a {
				color: darken($success-color, 5%);
			}
			
			p.meta {
				border-color:lighten($success-color, 20%);
			}
			
			&:before {
				background: lighten($success-color, 20%) url('/assets/img/icon-info.svg') no-repeat center center;
			}
		}
		
		&.alert {
			border-color:$alert-color;
			background: lighten($alert-color, 44%);
			
			a {
				color: darken($alert-color, 5%);
			}
			p.meta {
				border-color:lighten($alert-color, 34%);
			}
			
			&:before {
				background: lighten($alert-color, 34%) url('/assets/img/icon-alert.svg') no-repeat center center;
			}
		}
	}
}



.btn {
	
	display: inline-block;
	
	&.outline {
		
		border:2px solid #d4d4d4;
		border-radius:2px;
		padding: 10px 20px 11px;
		color: #181818;
		background: transparent;
		font-weight: 600;
		margin-bottom: 20px;
		
	}
	
	&.dark.outline {
		border-color: #979797;
		color: #fff;
	}
	
	&.cogwheel {
		
		&:before {
		
			content:'';
			width: 16px;
			height: 16px;
			display: inline-block;
			background: url('../img/icon-cogwheel-white-16.svg') no-repeat center center;
			background-size: contain;
			opacity: .8;
			vertical-align: middle;
			margin-right: 15px;
			position: relative;
			top:-1px;
		}
	}
	
}




.page-title {
	margin-bottom: 40px;
}


table {	
	thead {
		border-bottom:2px solid #979797;
		
		tr {
			th:first-child {
				padding-left:0;
			}
		}
	}
	
	tbody {
		tr {
			border-bottom:1px dashed $medium-gray;
			
			
			td {
				&:hover { background-color: #eee !important;}
			}
			
			&.clickable-row { cursor: pointer;}
			td:first-child {
				padding-left:0;
			}
		}
	}
}

@include breakpoint( large up ){
	h2.tab-header {
		//float: left;
		font-size: 18px;
		color: $header-color;
		font-weight: 600;
		padding-top:.5em;
		margin-bottom: -40px;
	}
}

.tabs {
	
	border-bottom: 1px solid $medium-gray;
	
	@include breakpoint( large up ){
		text-align: right;
	}
	
	li {
		position: relative;
		display: inline-block;
		float:none;
		top:1px;
		border:1px solid transparent;
		font-size: 14px;
		
		@include breakpoint(small only){
			a {
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 10px;
				padding-right:10px;
			}
		}
		
		a {
			font-size: 14px;
		}
		a:focus {
			outline: none;
		}
		
		
	}
	
	li.is-active {
		font-weight: 600;
		border-color:$medium-gray;
		border-bottom-color: #fff;

	}
}

.tabs-content {
	padding-top: 30px;
}

.accordion {
	
	margin-bottom: 40px;
	
	li {
		
		margin-bottom: -1px;
		border-bottom: 1px solid $light-gray;
		
		.accordion-title {
			font-weight: 600;
		}
		
		&.is-active {
			background: #F0F0F0;
			//padding-left:20px;
			//padding-right: 20px;
			border:1px solid #E4E4E4;
		}
	}
}

.transmission-meta {
	
	border-top:2px solid $dark-gray;
	padding-top: 20px;
	margin-top: 30px;
	margin-bottom: 30px;
	
	
}

dl {
	display: inline-block;
	padding: 0 20px 0 0;
	margin-right: 30px;
	border-right: 2px solid $medium-gray;
	margin-bottom: 20px;
	
	&:last-child {
		border-right: 0;
		padding-right: 0;
	}
	dt {
		font-weight: normal;
		line-height: 1;
	}
	dd {
		line-height: 1;
		font-weight: 600;
		color: $header-color;
	}
}

.receipt {
	margin-bottom: 30px;
}

main h5.divider {
	position: relative;
	text-transform: uppercase;
	color: $dark-gray;
	font-size: 13px;
	
	
	span {
		display: inline-block;
		background: #fff;
		padding-right: 10px;
		position: relative;
		z-index: 1;
	}
	
	&:after {
		content:'';
		width: 100%;
		display: block;
		position: relative;
		border-top:1px solid $light-gray;
		top:-9px;
		z-index: 0;
	}
}

main.dark-theme h5.divider {
	
	color: $dark-gray;
	
	span {
		background: #333;
	}
	
	&:after {
		border-top:1px solid #484848;
	}
}

.button[data-icon^='icon-']{
	
	padding-left:60px !important;
	position: relative;
	
	&:before {
		content:'';
		height: 24px;
		width: 24px;
		position: absolute;
		left:18px;
		top:50%;
		margin-top: -11px;
		display: block;
		
		
	}
}



.button {
	
	font-weight: 600;
	font-size: 15px;
	
	&.large {
		font-size: 15px;
		padding:.9em 1.5em;
		
	}
	
	&[data-icon=icon-reset]:before {	
		background: url('../img/icon-reset.svg') no-repeat center center;
	}

	&[data-icon=icon-order]:before {
		background: url('../img/icon-order.svg') no-repeat center center;
	}
	
	&[data-icon=icon-save]:before {
		background: url('../img/icon-save.svg') no-repeat center center;
	}
	
	&[data-icon=icon-print]:before {
		background: url('../img/icon-print.svg') no-repeat center center;
	}
	
	&[data-icon=icon-add]:before {
		background: url('../img/icon-add.svg') no-repeat center center;
	}
	
	&[data-icon=icon-search]:before {
		background: url('../img/icon-search.svg') no-repeat center center;
	}
	
	&[data-icon=icon-calculator]:before {
		background: url('../img/icon-calculator.svg') no-repeat center center;
	}
	&[data-icon=icon-forward]:before {
		background: url('../img/icon-forward.svg') no-repeat center center;
	}
	
	&[data-icon=icon-upload]:before {
		background: url('../img/icon-upload.svg') no-repeat center center;
	}
	
	&[data-icon=icon-check]:before {
		background: url('../img/icon-check.svg') no-repeat center center;
	}
}
	
	

.button.hollow {
	color: $header-color !important;
	background: #fff;
	border-radius:2px;
	&.secondary {
		border-color: $light-gray;
		background: #fff;
		&:hover{ border-color: $dark-gray;}
	}
	
	
}

.callout.success {
	border-color: $success-color;
	background: #fff;
}

.callout.warning {
	border-color: $warning-color;
	background: rgba($warning-color, .2);
	
	a {
		color: $warning-color;
		font-weight: 600;
		text-decoration: underline;
		display: inline-block;
		margin-right: 10px;
	}
}

.uploads-list {
	list-style: none;
	margin: 0 0 40px 0;
	
	li {
		display: block;
		a { 
			float: right;
			display: block;
			height: 20px;
			width: 20px;
			background: $alert-color url('../img/icon-close-white.svg') no-repeat center center;
			background-size: 8px 8px;
			border-radius:50%;
		}
	}
}

.user-table {
	
	margin-bottom: 40px;
	
	tbody {
		tr {
			border-bottom-color: #484848;
			
			td {
				h4 { }
			}
			
			td:first-child {
				
				width: 50px;
				
							
				&:before {
					content:'';
					display: inline-block;
					background: url('../img/icon-user-avatar.svg') no-repeat center center;
					width:40px;
					height: 40px;
					position: relative;
					top:2px;
				}
			}
			
			td:nth-child(2){
				span {
					white-space: nowrap;
				}
			}
			
			td:last-child {
				width: 80px;
			}
			
			.edit-user,
			.delete-user {
				width:25px;
				height: 25px;
				display: inline-block;
			}
			.edit-user {
				background: url('../img/icon-pencil-white.svg') no-repeat center center;
				
			}
			.delete-user {
				background: url('../img/icon-bin-white.svg') no-repeat center center;
				
			}
		}
	}
	
	.uppercase {
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 600;
	}
}