.rapports {
	
	.row {
		max-width: 1400px;
	}
	
	dl {
		margin-bottom: 50px;
	}
	
	table {
		margin-bottom: 50px;
		min-width: 100%;
		
		thead {
			tr:first-child {
				background: #404040;
				color:#fff;
			}
		}
	}
	
}