form {
	
	select {
		line-height: 1.8em;
		padding: 0 40px 0 20px;
	}
	
	input[type=checkbox],
	input[type=radio]{
		display: none;
	}
	
	input[type=checkbox]:not(old),
	input[type=radio   ]:not(old){
	  width     : 24px;
	  margin    : 0;
	  padding   : 0;
	  opacity   : 0;
	}	
	
	input[type=checkbox]:not(old) + label,
	input[type=radio   ]:not(old) + label{
	  display      : inline-block;
	  margin-left  : 0;
	  line-height  : 24px;
	  
	  &.with-tooltip {
		  padding-right: 10px;
		  [data-tooltip] {
			  top:10px;
			  left:6px;
		  }
	  }
	  
	}
	
	input[type=checkbox]:not(old) + label > span,
	input[type=radio   ]:not(old) + label > span{
	  display          : inline-block;
	  width            : 24px;
	  height           : 24px;
	  margin           : .5em 1em .5em 0em;
	  border           : 2px solid $medium-gray;
	  border-radius    : 2px;
	  background       : #fff;
	  vertical-align   : middle;
	  position: relative;
	}
	
	input[type=radio]:not(old) + label > span{
		border-radius: 50%;
	}
	
	
	
	input[type=checkbox]:not(old):checked + label > span:before{
	  content     : '✓';
	  display     : block;
	  //width       : 1em;
	  color       : $header-color;
	  font-size   : 18px;
	  line-height : 20px;
	  text-align  : center;
	  //text-shadow : 0 0 0.0714em rgb(115,153,77);
	  font-weight : bold;
	}
	
	input[type=radio]:not(old):checked + label > span:before{
		content:'';
		display: block;
		position: absolute;
		left: 50%;
		top:50%;
		width: 10px;
		height: 10px;
		margin: -5px 0 0 -5px;
		//border           : 0.0625em solid rgb(115,153,77);
		border-radius: 50%;
		background-color: $black;
		
	}
	
	
	fieldset {
		border:0;
		border-top:1px solid $light-gray;
		border-radius: 0;
		margin-top: .5em;
		margin-bottom: 40px;
		padding-top:10px;
		
		legend {
			font-size: 16px;
			font-weight: 600;
			color: $header-color;
			padding-right: 8px;
			position: relative;
			top:-2px;
			
			> span {
				font-weight: 400;
				font-size: 14px;
				color: $body-font-color;
				margin-left: 10px;
			}
		}
		
	}
	
	
	
	
	::-webkit-inner-spin-button { display: none; }
}

.find-location-wrapper {
	position: relative;
	
	input {
		position: relative;
		z-index: 2;
	}
}

ul.find-location-results {
	
	border:2px solid $dark-gray;
	border-top:0;
	position: absolute;
	top:100%;
	width: 100%;
	margin: -2px 0 0;
	padding-top: 2px;
	z-index: 2;
	background: #fff;
	
	li {
		display: block;
		line-height: 40px;
		padding: 0 20px;
		cursor: pointer;
		
		span {
			font-weight: 600;
		}
		&:hover {
			background: lighten($warning-color, 60%);
		}
	}
	
}

table.price-list{
	
	margin-bottom: 50px;
	
	tr {
		
		&.total {
			border-bottom: 2px solid $medium-gray;
		}
		
		td{
			&.title {
				font-weight: 600;
				color: $header-color;
				font-size: 120%;
			}
			&.price {
				
				color: $header-color;
				text-align: right;
				font-size: 120%;
				
				&.totals {
					font-weight: 600;
				}
			}
		}
	}
}


table.forwarding {
	
	width: auto;
	min-width: 30%;
	margin: 0 auto 50px;
	
	thead {
		tr {
			th {
				&:last-child {
					text-align: right;
					padding-right: 0;
				}
			}
		}
	}
	
	tbody {
		tr {
			td:last-child {
				text-align: right;
				padding-right: 0;
			}
		}
	}
}

.toggle {
	height: auto;
	opacity: 1;
	transition: all 300ms ease-in-out;
	overflow: visible;
}

.toggle.hidden-fields {
	opacity: 0;
	height: 1px;
	overflow: hidden;
}

label {
	&.with-tooltip {
	
		i.has-tip{
			float:right;
			width: 18px;
			height: 18px;
			display: block;
			background: url('../img/icon-tooltip.svg') no-repeat center center;
			text-decoration: none !important;
			border:none;
			position: relative;
			top:2px;
		}
	}
}

div.divider {
	display: block;
	text-align: center;
	border-top:1px dashed #ddd;
	position: relative;
	top:18px;
	margin: 20px 0;
	
	span {
		
		position: relative;
		top:-19px;
		background: #fff;
		border-radius:18px;
		height: 37px;
		border:2px solid #d4d4d4;
		line-height: 33px;
		text-transform: uppercase;
		color: #909090;
		display: inline-block;
		padding: 0 20px;
		
		
		
		&:after {
			content: attr(data-default-text);
		}
	}
	
	&:hover span {
		border-color: $primary-color;
	}
	
	&.active {
		span:after {
			content: attr(data-active-text);
		}
	}
}
