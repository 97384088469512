.tools {
	list-style: none;
	@include flex-grid-row('collapse', 'expand', null, true, 0) { }
		
	> li {
		@include flex-grid-column(6, 0);
		@include flex-align($x: center, $y: stretch);
		
		text-align: center;
		border-bottom:1px solid #eee;
		border-right: 1px solid #eee;
		padding: 30px;
		font-size: 14px;
		color: $header-color;
		font-weight: 600;
		position: relative;
		transition:all 300ms ease-in-out;
		overflow: hidden;
		
		@include breakpoint(small only){
			height: 40vw;
		}
		
		@include breakpoint(medium up){
			
			@include flex-grid-column(4, 0);
			font-size: 15px;
			height:27vw;
		}
		
		@include breakpoint(large up){
			
			@include flex-grid-column(3, 0);
			font-size: 16px;
			height:20vw;
		
			figure {
				background-size:35% 35% !important;
				transition: all 300ms ease-in-out;
			}
			
			&:hover {
				
				span {
					transform: scale(1.05);
				}
				figure {
				transform: scale(.95);
				opacity: 1;
				}
			}
					
			
		}
		
	
		@include breakpoint(small only){
			&:nth-child(2n){border-right: 0;}
		}
		@include breakpoint(medium only){
			&:nth-child(3n){border-right: 0;}
		}
		@include breakpoint(large only){
			&:nth-child(4n){border-right: 0;}
		}
				
		> a {
			display: block;
			color: $header-color;
			width: 100%;
			height: 100%;
			
			span.title {
				position: absolute;
				left: 0;
				top:auto;
				bottom:20%;
				width: 100%;
				//transition: all 300ms ease-in-out;
			}
		}
		
		&.has-sub:after {
			content:'';
			width: 16px;
			height: 12px;
			display: block;
			position: absolute;
			top:30px;
			right:30px;
			z-index: 1;
			background: url('../img/icon-hamburger-gray.svg') no-repeat center center;
		}
		
		&.has-sub .close-sub {
			content:'';
			width: 14px;
			height: 14px;
			display: block;
			position: absolute;
			top:30px;
			right:30px;
			background: url('../img/icon-close-white.svg') no-repeat center center;
			background-size: 14px 14px;
			opacity: 0;
			z-index: -1;
			cursor: pointer;
			
			@include breakpoint(small only){
				position:fixed;
				top:25vh;
				right:10vw;
				margin-right: 20px;
			}
			
			@include breakpoint(medium only){
				position:fixed;
				top:25vh;
				right:10vw;
				margin-right: 20px;
			}
		}
		
		&.sub-active.has-sub:after {
			opacity: 0;
			z-index: -1;
		}
		
		&.sub-active.has-sub .close-sub {
			opacity: 1;
			z-index: 100;
		}
		
		&:hover {
			background: #f7f7f7;
		}
		
		
		&.sub-active a > span.title {
			position: absolute;
			top:25px;
			left: 30px;
			color: #fff;
			z-index: 100;
			font-size: 15px;
			text-transform: uppercase;
			text-align: left;
			display: inline-block;
			width: auto;
			
			@include breakpoint(small only){
				position:fixed;
				top:25vh;
				left:10vw;
				padding-left: 38px;
				line-height: 1;
				
			}
			@include breakpoint(medium only){
				position:fixed;
				top:25vh;
				left:10vw;
				padding-left: 38px;
				line-height: 1;
				
			}
		}
		
		figure {
			position: absolute;
			margin: 0;
			padding: 0;
			left: 0;
			top:0;
			height: calc(100% - 60px);
			right: 0;
			display:block;
			background-size: 40% 40% !important;
			
		}
		
		.new-notices {
			display: block;
			height: 40px;
			width: 40px;
			background: $warning-color;
			border-radius:20px;
			line-height: 40px;
			text-align: center;
			position: absolute;
			top:25%;
			right: 25%;
			color: #fff;
			overflow: visible;
			
			&:after {
				content:'';
				display: block;
				width: 120%;
				height: 120%;
				border:1px solid $warning-color;
				position: absolute;
				top:-10%;
				left: -10%;
				border-radius:50%;
				animation: pulse 2s ease-out;
				animation-iteration-count: infinite;
			}
			
			&:before {
				content:'';
				display: block;
				width: 100%;
				height: 100%;
				border:1px solid $warning-color;
				position: absolute;
				top:0;
				left:0;
				border-radius:50%;
				animation: pulse 2500ms ease-out;
				animation-iteration-count: infinite;
			}
		}
		
		@-moz-keyframes pulse {
		 0% {
		    -moz-transform: scale(1.1);
		    opacity: 0.0;
		 }
		 25% {
		    -moz-transform: scale(1.2);
		    opacity: 0.2;
		 }
		 50% {
		    -moz-transform: scale(1.3);
		    opacity: 0.5;
		 }
		 75% {
		    -moz-transform: scale(1.4);
		    opacity: 0.2;
		 }
		 100% {
		    -moz-transform: scale(1.5);
		    opacity: 0.0;
		 }
		}
		
		@-webkit-keyframes "pulse" {
		 0% {
		    -webkit-transform: scale(1.1);
		    opacity: 0.0;
		 }
		 25% {
		    -webkit-transform: scale(1.2);
		    opacity: 0.2;
		 }
		 50% {
		    -webkit-transform: scale(1.3);
		    opacity: 0.5;
		 }
		 75% {
		    -webkit-transform: scale(1.4);
		    opacity: 0.2;
		 }
		 100% {
		    -webkit-transform: scale(1.5);
		    opacity: 0.0;
		 }
		}

		&.tracking figure {
			background: url('../img/illustrations/tracking.svg') no-repeat center center;
		}
		
		&.transmission figure {
			background:url('../img/illustrations/transmission.svg') no-repeat center center;
		}
		
		&.pickup figure {
			background:url('../img/illustrations/pickup.svg') no-repeat center center;
		}
		
		&.notices figure {
			background:url('../img/illustrations/notices.svg') no-repeat center center;
		}
		
		&.invoices figure {
			background:url('../img/illustrations/invoices.svg') no-repeat center center;
		}
		
		&.rapports figure {
			background:url('../img/illustrations/rapports.svg') no-repeat center center;
		}
		
		&.regulations figure {
			background:url('../img/illustrations/regulations.svg') no-repeat center center;
		}
		
		&.complaints figure {
			background:url('../img/illustrations/complaints.svg') no-repeat center center;
		}
		
		&.warehousing figure {
			background:url('../img/illustrations/warehousing.svg') no-repeat center center;
		}
		
		&.freight-forwarding-collect figure {
			background:url('../img/illustrations/freight-forwarding-collect.svg') no-repeat center center;
		}
		
		&.freight-forwarding-arrival figure {
			background:url('../img/illustrations/freight-forwarding-arrival.svg') no-repeat center center;
		}
		
		&.user figure {
			background:url('../img/illustrations/login.svg') no-repeat center center;
		}
		
		
		/*
		@include breakpoint(xxlarge up){
			padding-top: 12%;
			@include flex-grid-column(2, 0);
		}*/
		/*
		@include breakpoint(xxlarge up){
			@include flex-grid-column(1, 0);
		}*/
		&.sub-active .sub-menu {
			opacity: 1;
			z-index: 99;
			//top:0;
			transition:all 300ms ease-in-out;
			
			@include breakpoint( small only){
				
				//top:10vw;
				//left: 10vw;
			}
		}
		
		
		
		.sub-menu {
			
			
			
			position: absolute;
			top:100%;
			width: 100%;
			height: 100%;
			background: #444;
			
			opacity: 0;
			z-index: -1;
			transition: all 300ms ease-in-out;
			padding: 20px;
			
			
			&:after {
				content:'';
				@include breakpoint( large up){
					position: absolute;
					display: block;
					width: 100%;
					top:0px;
					height: 70px;
					left:0;
					border-top:1px solid rgba(#fff, .15);
					background: rgba(#000, .07);
				}
			}
			
			@include breakpoint( small up){
				position: fixed;
				top:22vh;
				left:10vw;
				
				width: calc(100vw - 20vw);
				height: auto;
				padding-top:50px;
				//height: calc(40vh);
			}
			
			@include breakpoint( large up){
				position: absolute;
				top:0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 70px 30px 20px;
			}
			
			@include breakpoint( xlarge up){
				width: 100%;
				height: 100%;
				padding: 70px 40px 30px;
			}
			/*
			&:before {
				content:attr(data-title);
				text-align: left;
				display: block;
				color: #fff;
				padding: 4px 0px 15px;
				font-size: 18px;
				font-weight: 600;
			}
			*/
			
			ul {
				
				@include breakpoint( large up){
					@include vertical-align(50%);
					margin: 0 auto;
					//max-width: 320px;
				}
				
				
				li {
					display: block;
					border:0;
					//border-top: 1px solid rgba(#fff, .15);
					text-align: left;
					margin: 0;
					padding: 12px 0px;
					font-size: 15px;
					position: relative;
					@include breakpoint( xlarge up){
						font-size: 18px;
					}
										
					a {
						display: block;
						color: #fff;
						padding-right: 30px;
						&:after {
							content:'';
							background: url('../img/icon-arrow-right-white.svg') no-repeat center center;
							position: absolute;
							right: 0;
							top:50%;
							margin-top: -6px;
							width:16px;
							height: 14px;
						}
					}
				}
			}			
		}
	}	
}